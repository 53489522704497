import React from 'react'
import styled from 'styled-components'

import SEO from '@/components/seo'
import CalculatorStructure from '@/components/CalculatorStructure'
import { Gpa } from '@/components/Calculator/Grade'

import { CALCULATORS } from '@/helpers/mocks/calculators'
import SectionInfoCard from './SectionInfoCard'

const StyledGpaCalculator = styled(Gpa)`
  min-width: unset;
  max-width: unset;
`

const GpaCalculator = () => (
  <>
    <SEO title="GPA" />
    <CalculatorStructure
      section="grade"
      headerTitle="grade"
      title={CALCULATORS.grade.gpa.title}
      description={CALCULATORS.grade.gpa.description}
      calculatorNode={<StyledGpaCalculator />}
      asideCards={<SectionInfoCard />}
    />
  </>
)

export default GpaCalculator
